import React from "react";
import '../Styles/CategoryBanner.css';

const CategoryBannerTechnology= () => {   

    return (
        <div>
            <container id="technology-banner">
                Technology
            </container> 
        </div>
    
    )
}

export default CategoryBannerTechnology;

