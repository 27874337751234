import React from "react";
import '../Styles/CategoryBanner.css';

const CategoryBannerEngineering= () => {   

    return (
        <div>
            <container id="engineering-banner">
                Engineering
            </container> 
        </div>
    
    )
}

export default CategoryBannerEngineering;

