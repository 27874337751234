import React from "react";
import '../Styles/CategoryBanner.css';

const CategoryBannerScience= () => {   

    return (
        <div>
            <container id="science-banner">
                Science
            </container> 
        </div>
    
    )
}

export default CategoryBannerScience;

