import React from "react";
import '../Styles/CategoryBanner.css';

const CategoryBannerMathematics= () => {   

    return (
        <div>
            <container id="mathematics-banner">
                Mathematics
            </container> 
        </div>
    
    )
}

export default CategoryBannerMathematics;

