import React from "react";
import '../Styles/CategoryBanner.css';

const CategoryBannerHome= () => {   

    return (
        <div>
            <container id="home-banner">
                Home
            </container> 
        </div>
    
    )
}

export default CategoryBannerHome;

